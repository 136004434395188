import React from "react";

const DashNav = () => {
  return (
    <div>
      <h1>DashNav</h1>
    </div>
  );
};

export default DashNav;
